/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')
try {
    window.Popper = require('popper.js').default
    window.$ = window.jQuery = require('jquery')

    require('bootstrap')
} catch (e) {}

window.Vue = require('vue')
import VueSimpleSVG from 'vue-simple-svg'
import { getQueryParamMixin } from './mixins/getQueryParamMixin'
import { getSvgFilePathMixin } from './mixins/getSvgFilePathMixin'

window.Vue.use(VueSimpleSVG)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 * Exclude the BibleCloud components, as they have their own app entry point
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
const files = require.context('./', true, /^(?:(?!biblecloud\/components).)*\.vue$/i)
files.keys().map(key =>
    window.Vue.component(
        key
            .split('/')
            .pop()
            .split('.')[0],
        files(key).default
    )
)

/* A Mixin for global funtions */
window.Vue.mixin(getQueryParamMixin)
window.Vue.mixin(getSvgFilePathMixin)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new window.Vue({
    el: '#app'
})
